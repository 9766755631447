.testimonial-section {
  margin-top: 123px;

  .testimonial-title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
    color: $title-color;

    @media (max-width: 600px) {
      font-size: 36px;
    }
  }

  .testimonial-slider {
    background-image: url("/img/testimonial/background-img.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0px 100px 0px;

    .slider-items {
      width: 60%;
      min-height: 500px;
      @media (max-width: $sm) {
        cursor: grab;
      }
      .slider-list {
        overflow: initial;
        .slider-item {
          min-height: 450px;
          .slider-img {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            img {
              width: 168px;
              height: 168px;
              border-radius: 50%;
              margin: 0 auto;

              @media (max-width: 350px) {
                width: 100px;
              }
            }
          }

          .slider-name {
            text-align: center;
            margin-top: 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 130%;
            color: $title-color;
            span {
              position: relative;
              z-index: 10;
              display: inline-block;
              background-color: #47ed76;
              overflow: hidden;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #ffffff;
                clip-path: polygon(-16% 55%, 216% -20%, -10% 0%);
                z-index: -1;
              }
            }
          }

          .testimonial-stars {
            text-align: center;
            margin-top: 12px;
          }

          .desc-wrapper {
            position: relative;

            &::before,
            &::after {
              content: "";
              position: absolute;
              width: 32px;
              height: 24px;
              background-repeat: no-repeat;
            }

            &::before {
              top: -20px;
              left: -20px;
              background: url("../../img/testimonial/left-inverted-comma .svg");
            }

            &::after {
              bottom: 0;
              right: -30px;
              background: url("../../img/testimonial/right-inverted-comma.svg");
            }
          }

          .slider-desc {
            margin-top: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            text-align-last: center;
            color: $title-color;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word !important;
          }
        }
      }

      .testimonials-control {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);

        .prev,
        .next {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          border: 1.5px solid #f7971e;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1;
          position: absolute;
          transform: translateX(-50%);
        }

        .prev {
          left: calc(50% - 32px);
        }

        .next {
          left: calc(50% + 32px);
        }

        img {
          height: 15px;
        }
      }
    }
  }
}
