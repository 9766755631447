.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.75);
  z-index: 99999;

  .video-modal {
    max-width: 780px;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;


    button {
      color: $white;
      border: none;
      outline: none;
      background-color: transparent;
      position: absolute;
      width: 70px;
      height: 70px;
      right: -70px;
      top: 0;

      @media (max-width: $md) {
        width: 50px;
        height: 50px;
        top: -50px;
        right: 10px;
      }
    }

    button svg {
      width: 100%;
      height: 100%;
      fill: #fff;
      stroke-width: 2px;
    }
  }
}
