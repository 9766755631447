@import "../dark_variables";

body {
  &.dark {
    color: $white;
    background: $dark-secondary;

    * {
      color: $white !important;
    }

    section {
      background-color: $dark-secondary;
    }

    .bottom-top-button {
      background: $white;
      span::after {
        color: $dark-primary !important;
      }
    }

    .cursor-outer {
      border-color: $white;
    }

    .cursor-inner {
      background: $white;
    }

    .see-more-btn,
    .see-more-btn.primary {
      border-color: rgba($color: #fff, $alpha: 0.2);
    }

    .preloader.dark .bg {
      background: $dark-secondary;
    }

    .expertise-modal {
      background: $dark-primary;
    }

    .mobile-menu {
      background: $black;
    }

    .optional-menu-open {
      background: $dark-secondary;
    }
    .optional-mobile-menu {
      background: $dark-primary;
    }

    .optional-mobile-menu .optional-menu-items a.active {
      background: $dark-secondary;
    }

    .optional-mobile-menu .optional-menu-items a.border-top {
      border-top-color: $dark-secondary !important;
    }

    .mobile-menu .mobile-menu-items a svg path {
      fill: $white;
    }
    .mobile-menu .mobile-menu-items a.active svg path {
      fill: $primary-color;
    }

    .optional-menu-close {
      background: $dark-secondary;
    }

    .testimonial-section
      .testimonial-slider
      .slider-items
      .slider-list
      .slider-item
      .slider-name
      span {
      background-color: #c47b20e6;
    }

    .testimonial-section .testimonial-slider .slider-name span::after {
      background: $dark-secondary !important;
    }

    .title-signature:after {
      background-image: url("../../img/title-signature.svg") !important;
    }

    .section-heading sub {
      display: none;
    }

    .overlay {
      background-color: $dark-primary;
      &.expertise-overlay {
        background-color: rgba($color: #000, $alpha: 0.75);
      }
    }

    .expertise-backdrop {
      background: $dark-secondary;
    }

    .modal-close-btn {
      border: 1px solid #fff;
      &:focus {
        box-shadow: none;
      }
    }

    header {
      nav {
        background: rgb(15 22 33);
        box-shadow: 0px -12px 6px 6px $white;

        ul.menubar li.nav-item::after {
          background-color: $dark-secondary !important;
        }
      }

      &.sticky {
        background-color: $dark-primary;
      }

      .nav-item .child-menu {
        background-color: $dark-primary;
      }

      .nav-item .child-menu a.active {
        background-color: $dark-secondary !important;
      }

      .nav-item .child-menu a.active,
      nav ul.child-menu li a:hover {
        background: $dark-secondary;
      }
    }

    .hero-banner {
      background: $dark-primary;

      .hero-profile .illustrator {
        opacity: 0.5;
      }

      .social-media li a {
        background: $dark-secondary;
        &:hover {
          background: $dark-active-color;
          i {
            color: $dark-secondary !important;
          }
        }
      }

      &.developer {
        .hero-title h1:after {
          background-image: url("../../img/title-signature-white.svg");
        }
        .hero-top .hero-tooltip .tooltip-text {
          background: $white;
          color: $dark-primary !important;
        }
        .social-media a:hover {
          background: $dark-active-color;
        }
      }

      .hero-content:before {
        background: $dark-primary;
      }

      .hero-profile:before,
      .hero-profile:after {
        background: rgba($dark-secondary, 0.5);
      }

      .hero-profile .overlay {
        background-color: $dark-secondary;
        @media (max-width: $md) {
          background: transparent;
        }
      }

      &.designer.social-media a {
        background: $dark-secondary;
        &:hover {
          background: $black;
        }
      }
    }

    .expertise .service__card {
      border: 1px solid rgba($color: #fff, $alpha: 0.2);
      &:hover {
        background: $dark-primary;
        box-shadow: none;
      }
    }

    .experience .experience__item h2 span {
      background: transparent;
      -webkit-text-fill-color: $white;
      color: #fff !important;
    }

    .experience .experience__item h4 {
      color: $primary-color !important;
    }

    .hero-button a.lets-talk-btn,
    .hero-banner .hero-button button.lets-talk-btn {
      border-color: rgba($color: #fff, $alpha: 0.5) !important;
    }
    .hero-banner .hero-button a.lets-talk-btn svg path,
    .hero-banner .hero-button button.lets-talk-btn svg path {
      fill: $white;
    }

    .owl-nav .owl-prev,
    .expertise .owl-nav .owl-next {
      background: $dark-primary;
      border-color: rgba($color: #fff, $alpha: 0.2);
    }

    .expertise .owl-nav .owl-prev.disabled,
    .expertise .owl-nav .owl-next.disabled {
      border-color: transparent;
    }

    .experience ul.nav.nav-tabs li .nav-link.active {
      background: $dark-active-color;
    }

    .experience ul.nav.nav-tabs {
      background: $dark-primary;
      border: 1px solid rgba($color: #fff, $alpha: 0.2);

      li .nav-link svg path {
        fill: $white;
      }
    }

    .experience .experience__timeline ul li.active::after {
      background: $dark-active-color;
    }

    .experience .experience__timeline ul li.active + li::before {
      background: $dark-active-color;
    }

    .experience .experience__timeline ul li.active .left .circle {
      border-color: $dark-active-color;
    }

    .experience .experience__timeline ul li .right::before {
      background: $dark-active-color;
    }

    .experience .experience__timeline ul li .right {
      background: $dark-primary;
      box-shadow: none;
    }

    .experience .experience__item {
      background: $dark-primary;
    }

    .project-list-section {
      background-color: transparent;
    }

    .project-section {
      background: $dark-primary;

      .feature-tab .nav-link {
        border-color: rgba($color: #fff, $alpha: 0.2);

        &.active {
          background: $dark-active-color;
        }
      }

      .project-item {
        background-color: $dark-secondary;
      }

      .project-item .caption {
        background: rgba($dark-primary, 0.7);
        p {
          background: $dark-secondary;
        }
      }
    }

    .project-list-section .project-list {
      background: $dark-primary;
    }

    .blog-section {
      z-index: 10;

      &:after {
        background: $dark-primary;
        z-index: -1;
      }

      .blogs-item a .blog-item {
        background: $dark-secondary;
        border-color: rgba($color: #fff, $alpha: 0.2);
        box-shadow: none;
      }
    }

    .contact-section {
      .contact-card-item {
        border-color: rgba($color: #fff, $alpha: 0.2);
      }

      .contact-form {
        border-color: rgba($color: #fff, $alpha: 0.2);
      }

      .button-schedule {
        border-color: rgba($color: #fff, $alpha: 0.2);
      }

      .send-button .send-me {
        background: $dark-primary;
      }

      .input-icons .input-field {
        background: $dark-primary;

        &::placeholder {
          color: $white;
        }

        &:focus {
          outline-color: $dark-primary;
        }
        .input-icons {
          border-color: $dark-primary;
        }
      }
    }

    footer {
      background: $dark-primary;

      .social-media a {
        background: $dark-secondary;
        &:hover {
          background: $dark-active-color;
          i {
            color: $dark-secondary !important;
          }
        }
      }
    }
  }
}
