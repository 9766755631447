/* **************************************************
    Header section
************************************************** */

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  &.sticky {
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1050;
    box-shadow: 10px 50px 100px rgba(124, 113, 105, 0.1);
    animation: sticky 1s;
  }

  nav {
    z-index: 10;
    background: rgb(255 255 255 / 50%);
    box-shadow: 0px -12px 6px 6px #000;
    @media (max-width: $sm) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .navbar-toggler {
      @media (max-width: $sm) {
        padding: 0;
      }
    }

    .navbar-brand {
      img {
        width: 180px;
      }
    }

    ul.menubar {
      li {
        position: relative;

        &.nav-item {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: #000;
            transition: width 0.3s ease-in-out;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }

        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          color: $title-color;
          font-family: "Nunito Sans", sans-serif;

          @media (max-width: $md) {
            font-size: 16px;
          }

          &:hover {
            color: $title-color;
          }
        }

        &:hover > .child-menu {
          visibility: visible;
          opacity: 1;
          margin-top: 0;
        }
      }
    }
  }

  .nav-item .child-menu {
    background-color: $white;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 12px 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 42px;
    min-width: 240px;
    margin-top: 10px;

    .child-menu {
      margin-top: 0;
      transition: none;
    }

    a {
      text-decoration: none;
      padding: 8px 16px;
      display: block;

      &.active {
        background: linear-gradient(119.99deg, #f7971e 21.9%, #ffd200 125.17%);
        color: #fff;
      }
    }

    li:hover {
      transition: all 0.3s ease-in-out;

      > a,
      a.active {
        color: $white !important;
        background: $sub-menu-bg-color;
      }
    }

    li:last-child a {
      border-bottom: none;
    }

    .child-menu {
      padding: 0;
      left: 100%;
      border-radius: 0 6px 6px 6px;
      top: 0;
    }
  }

  .optional-menu-open {
    position: absolute;
    top: 25px;
    right: 15px;
    border: none;
    outline: 0;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(73, 71, 167, 0.1);
    border-radius: 6px;
    z-index: 100;
    display: none;

    @media (max-width: $sm) {
      display: block;
    }
  }
}

// Color option menu
.optional-mobile-menu {
  background: #ffffff;
  box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.195737),
    0px 20px 80px rgba(0, 0, 0, 0.14);
  width: 300px;
  position: fixed;
  right: -200%;
  top: 0;
  z-index: 99999;
  transition: all 0.4s ease;

  &.show {
    right: 0;
    transition: all 0.4s ease;
  }

  .optional-menu-items {
    padding-top: 70px;
    overflow-x: hidden;
    overflow-y: auto;

    li {
      list-style: none;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      color: #000;
      font-weight: 400;
      display: block;
      font-family: $body-font-family;

      span {
        font-size: 10px;
      }

      &.border-top {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
      &.active {
        background-color: $primary-color;
        color: $white;
      }
    }

    .dropdown-btn {
      margin: 10px 0px;
      margin-bottom: 0;
      min-width: 100%;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      padding-right: 5px;
      margin-top: 0;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #f9f9f9;
        border-radius: 5px;
      }
    }

    .has-child {
      background: #ffffff;
      width: 100%;
      padding-bottom: 0;
      border-radius: 8px;
      display: none;

      &.active {
        display: block;
      }

      li {
        width: 100%;
        color: #000;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        a {
          padding: 10px 10px 10px 30px;
        }
      }
    }
  }

  .optional-menu-close {
    position: absolute;
    left: 5px;
    border-radius: 5px;
    top: 5px;
    border: none;
    outline: 0;
    background-color: #f9f9f9;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
    font-size: 20px;
  }
}

@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
