/* **************************************************
    Base Styles
************************************************** */

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  overflow-x: hidden;
}

.overflow-x {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Nunito Sans", sans-serif;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

.modal-close-btn {
  position: absolute;
  right: 20px;
  top: 90px;
  background: #fff;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  padding: 0;
  margin: 0;
  opacity: 0.75;

  @media (max-width: $md) {
    top: 100px;
    right: 30px;
  }

  @media (max-width: $sm) {
    top: 93px;
    right: 20px;
  }

  @media (max-width: $xs) {
    top: 92px;
    right: 20px;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    height: 36px;
    width: 36px;
  }
}

// Bootstrap Modal modified
.modal {
  @media screen and (max-width: $sm) {
    max-height: 100%;
  }
}

.modal-content {
  border-radius: 16px;
  overflow: auto;
  width: calc(100% - 20px);
  margin: auto;
  border: none;
}

.modal-lg {
  max-width: 700px;
}
