/* **************************************************
    Developer hero banner.js section
************************************************** */

.hero-banner.developer {
  text-align: center;
  height: 100vh;

  @media screen and (max-height: 699px) and (min-width: $sm) {
    min-height: 740px;
  }

  @media screen and (max-width: $md) {
    padding: 70px 0 50px 0;
  }

  @media screen and (max-width: $sm) {
    min-height: 100vw;
    height: 100%;
  }

  .container {
    z-index: 111;
    position: relative;
  }

  .hero-top {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    .hero-sub-title {
      font-family: "Outfit";
      font-weight: 600;
      font-size: 24px;
      color: $black;
      line-height: 130%;
      .text-shape {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 102%;
          height: 102%;
          background: #f9a01a;
          border-radius: 4px;
          z-index: -1;
          transform: skew(12deg, 0deg);
        }
      }
    }

    .hero-avatar {
      margin-bottom: 30px;
      border-radius: 50%;

      img {
        width: 106px;
        height: 106px;
        border-radius: 50%;
      }
    }

    .hero-tooltip {
      position: absolute;

      @media screen and (max-width: $xs) {
        position: relative;
      }

      .tooltip-text {
        font-size: 14px;
        font-weight: 500;
        color: $white;
        padding: 5px 12px;
        background-color: #3a3f4e;
        border-radius: 5px;

        @media (max-width: 480px) {
          font-size: 12px;
        }
      }

      .tooltip-icon {
        display: inline-block;
        transform: translate(-1px, -22px);
      }

      &.left {
        bottom: 40px;
        left: -160px;

        @media screen and (max-width: $sm) {
          bottom: 70px;
          left: -94px;
        }
        @media screen and (max-width: $xs) {
          bottom: 0;
          left: 0;
          margin: 5px 0 20px 0;
        }
      }

      &.right {
        bottom: 20px;
        right: -140px;

        .tooltip-icon {
          display: inline-block;
          transform: translate(4px, -26px);
        }

        @media screen and (max-width: $sm) {
          bottom: 45px;
          right: -55px;
        }
      }
    }
  }

  .hero-title h1 {
    max-width: 100%;
    position: relative;
    display: inline-block;

    @media (max-width: $md) {
      font-size: 60px;
    }

    @media (max-width: $sm) {
      font-size: 46px;
    }

    @media (max-width: $xs) {
      max-width: 400px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -55px;
      right: 10px;
      background-image: url("../../img/title-signature-black.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 50%;
      height: 50px;

      @media (max-width: $xs) {
        right: 25%;
        bottom: -60px;
      }
    }
  }

  .fan-icons {
    position: absolute;
    right: -84px;
    bottom: 15px;
    @media screen and (max-width: $sm) {
      right: -55px;
      bottom: -20px;
      svg {
        height: 60px;
        width: 60px;
      }
    }
  }

  .hero-pera p {
    margin: 30px auto 39px auto;
    text-align: center;

    @media (max-width: $sm) {
      margin: 40px auto 30px auto;
    }
  }

  .circle-background {
    left: 0;
    right: 0;
    margin: auto;
  }

  .social-media a {
    background-color: #3a3f4e;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 28px;
    border-radius: 5px;
    &:hover {
      background: $hero-social-bg;
    }
  }
}
