/* **************************************************
    Project list section
************************************************** */
.project-list-section {
  .project-list {
    padding: 54px 5%;
    margin-top: -102px;
    background: $bg-color-project-count;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  .box {
    border-right: 1px solid $black;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 110%;
      text-align: center;
      color: $black;

      span {
        background: $text-bg;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }

      @media(max-width: 600px) {
        font-size: 36px;
      }

      sup {
        font-weight: 300;
        top: -5px;
        padding-left: 2px;
        background: $text-bg;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: $black;
      margin: 0;

      @media(max-width: 600px) {
        font-size: 12px;
      }
    }
  }
}
