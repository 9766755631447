.theme {
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
    fill: $white;
    stroke: #fff;
  }

  &.light {
    background: $sub-menu-bg-color;
  }

  &.dark {
    background-color: $white;
    svg {
      fill: $black;
      stroke: $black;
      * {
        color: #000 !important;
      }
    }
  }
}

.optional-mobile-menu .theme {
  position: absolute;
  right: 5px;
  top: 10px;
}
