/* **************************************************
    Project section
************************************************** */
.project-section {
  background: $bg-color-project;
  padding: 110px 0 162px 0;

  @media screen and (max-width: $sm) {
    padding: 55px 0 162px 0;
  }

  .section-heading {
    margin-bottom: 50px;
    color: $white;

    sub {
      background-color: $white;
    }
  }

  // Project tab
  .feature-tab {
    margin-bottom: 36px;
    width: auto;

    .nav-link {
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      color: $black;
      background-color: transparent;
      transition: $transition-default;
      text-transform: uppercase;
      margin-bottom: 16px;
      border-radius: 30px;
      padding: 15px 34px;
      color: #0d2f3f;
      border: 1px solid #23262f;
      text-transform: capitalize;

      @media screen and (max-width: $md) {
        font-size: 18px;
      }

      @media screen and (max-width: $sm) {
        font-size: 16px;
      }

      @media screen and (max-width: $xs) {
        font-size: 14px;
      }

      &.active {
        border: none;
        background: linear-gradient(119.99deg, #f7971e 21.9%, #ffd200 125.17%);
      }
    }

    .nav-link:not(:last-child) {
      margin-right: 16px;
    }
  }

  .project-title {
    font-weight: 600;
    font-size: 48px;
    color: $title-color;

    @media (max-width: 600px) {
      font-size: 32px;
    }
  }

  .project-item {
    cursor: pointer;
    padding: 24px;
    background-color: $white;
    border-radius: 16px;
    margin-bottom: 26px;
    position: relative;
    overflow: hidden;
    display: block;
    text-decoration: none;

    .project-item-img {
      width: 100%;
      position: relative;
      border-radius: 16px;
      overflow: hidden;

      img {
        width: 100%;
        opacity: 1;
        display: block;
        height: auto;
        transition: 0.5s ease;
        backface-visibility: hidden;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 138px;
        height: 138px;
        border-radius: 50%;
        background: $post-hover-bg;
        color: $white;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        margin: 0;
        z-index: 10;
        opacity: 0;
        transition: 0.5s ease;
      }
    }

    .caption {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: rgba($color: $black-color-rgb, $alpha: 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover .caption,
    &:hover .project-item-img p {
      opacity: 1;
    }

    .details {
      h4 {
        margin: 20px 0 0 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: $primary-color;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word !important;
      }

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
        color: #333333;
        margin: 10px 0 10px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word !important;

        @media (max-width: 600px) {
          font-size: 18px;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba($color: $black-color-rgb, $alpha: 0.6);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word !important;
        margin-bottom: 16px;
      }
    }
  }
}

.feature-project-modal {
  padding: 0;
  border-radius: 16px;

  .details {
    padding: 30px 30px 20px 30px;

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
      color: $primary-color;
      word-break: break-word !important;
    }

    h2 {
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      color: #333333;
      margin: 10px 0 15px 0;

      @media (max-width: $xs) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba($color: $black-color-rgb, $alpha: 0.6);
      word-break: break-word !important;
      margin-bottom: 16px;
      text-align: justify;
    }
  }
}
