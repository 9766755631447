/* **************************************************
    Client section
************************************************** */

.client-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;

  @media screen  and (max-width: $sm){
    padding-bottom: 60px;
  }

  .section-heading {
    margin: 0 0 80px 0;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 60px;
    column-gap: 30px;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
      }

      img {
        max-width: 70%;
        width: auto;
        max-height: 80%;
        filter: grayscale(100%);
        transition: filter 1s;
      }

      &:hover img {
        filter: none;
      }
    }

    @media(max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media(max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 3em;
    }
    @media(max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 576px) {
      gap: 1rem;
    }

  }

}