.expertise {
  padding: 110px 0 100px 0;
  position: relative;

  .social-media {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;

    li {
      margin-right: 12px;
      margin-bottom: 0;
    }

    li a {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      border-radius: 50%;
      background: #3a3f4e;
      text-decoration: none;
      color: $white;

      &:hover {
        background: $primary-color;
        color: $white;
      }
    }
  }

  @media screen and (max-width: $sm) {
    padding: 25px 0 30px 0;
  }

  .expertise-top {
    margin-bottom: 30px;
  }

  .owl-stage {
    padding: 20px 0px;
    height: 100%;
  }

  .expertise__services-carousel {
    display: flex;
    & > div {
      margin-right: 10px;
    }
  }

  .service__card {
    border: 1px solid rgba($color: $black-color-rgb, $alpha: 0.2);
    border-radius: 10px;
    min-width: 250px;
    min-height: 310px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: box-shadow 0.3s ease-in-out, background 0.3s !important;

    .card-icon {
      transition: background-color 0.3s ease-in-out !important;

      svg path {
        transition: fill 0.3s ease-in-out !important;
      }
    }

    &:hover {
      background: $white;
      box-shadow: 0 10px 15px 0 rgba(124, 113, 105, 0.1);

      .card-icon {
        background: $expertise-icon-bg;

        svg path {
          fill: $white;
        }
      }
    }

    .card-icon {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      background-color: $blue-shades;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition-default;

      &:hover {
        background-color: $primary-color;
      }
    }

    .service__content {
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: $title-color;
        margin-top: 28px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media screen and (max-width: $xl) {
          font-size: 22px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $text-color;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      button.learn__more {
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .btn-text,
        .btn-icon {
          display: inline-block;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $title-color;
        }

        &:hover {
          .btn-text,
          .btn-icon {
            color: $primary-color;
          }
        }

        .btn-icon {
          display: inline-block;
          margin-left: 10px;
          transform: translateY(2px);
        }
      }
    }
  }

  // Slider controller
  .owl-nav {
    display: flex;

    .owl-prev,
    .owl-next {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1.5px solid $primary-color;
      color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #fff;

      &.disabled {
        border-color: rgba(0, 0, 0, 0.22);
        color: rgba(0, 0, 0, 0.22);
      }

      &:not(:last-child) {
        margin-right: 18px;

        @media (max-width: 22em) {
          margin-right: 5px;
        }
      }

      @media (max-width: 22em) {
        width: 35px;
        height: 35px;
      }
    }
  }

  .scroll-to-bottom {
    position: absolute;
    top: -47px;
    right: calc(50% - 26px);
    z-index: 999;
    a {
      background-color: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      svg {
        opacity: 0.85;
      }
      span.line {
        height: 24px;
        width: 4px;
        position: absolute;
        top: 20px;
        left: calc(50% - 2px);
        border-radius: 5px;
        background: $scroll-bottom;
        animation: scrollToBottom 2s ease-out infinite;
      }
      span.dot {
        height: 7px;
        width: 7px;
        position: absolute;
        bottom: 20px;
        left: calc(50% - 3px);
        border-radius: 5px;
        background: $scroll-bottom;
      }
    }
  }
}
@keyframes scrollToBottom {
  0% {
    transform: translateY(-0.4em);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    transform: translateY(0.9em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes scrollToBottom {
  0% {
    transform: translateY(-0.4em);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    transform: translateY(0.9em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.expertise-modal {
  padding: 40px 15px 40px 40px;
  border-radius: 16px;

  @media screen and (max-width: $sm) {
    padding: 15px;
  }

  .expertise-img {
    padding-bottom: 30px;

    img {
      height: 125px;
      width: 125px;

      @media screen and (max-width: $sm) {
        height: 80px;
        width: 80px;
      }
    }
  }

  .details {
    @media screen and (max-width: $sm) {
      padding-bottom: 15px;
    }

    h3 {
      margin: 0 0 15px 0;
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      color: $black;
      word-break: break-word !important;

      @media screen and (max-width: $sm) {
        font-size: 26px;
        line-height: 30px;
      }
    }

    p {
      font-weight: 400;
      text-align: left;
      color: #727272;
      font-size: 18px;
      line-height: 36px;
      padding-right: 15px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (max-width: $sm) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
