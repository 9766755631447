/* **************************************************
    Cursor animation
************************************************** */

$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);

html:hover {
  .cursor {
    opacity: 1;
  }
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  opacity: 0;
  transition: opacity .2s $ease-out-quart;
  pointer-events: none;

  @media screen and (max-width: $sm) {
    display: none;
  }

  &--hover {
    .cursor-inner {
      transform: scale(.5);
      opacity: 0;
    }

    .cursor-outer {
      transform: scale(1.4);
      border-color: #000;
      opacity: 1;
    }
  }
}

.cursor-move-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}

.cursor-move-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor-inner {
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 50%;
  transition: transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
}

.cursor-outer {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(#000, .75);
  border-radius: 50%;
  box-sizing: border-box;
  transition: border .4s $ease-out-quart, transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
}
