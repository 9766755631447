/* **************************************************
    Contact seciton
************************************************** */

.contact-section {
  position: relative;
  overflow: hidden;
  padding: 110px 0 120px 0;

  @media screen and (max-width: $sm) {
    padding: 55px 0 60px 0;
  }

  .section-heading {
    margin-bottom: 80px;
  }

  .section-heading sub {
    background-color: $black;
  }

  .contact-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $text-color;
    margin-top: 10px;
  }

  // Contact form
  .contact-form {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-left: 40px;
    padding: 30px;
    position: relative;
    @media screen and (max-width: $md) {
      margin-left: 0;
    }
  }

  .contact-card {
    @media screen and (max-width: 911px) {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }
  }

  .contact-card-item {
    height: 120px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0.5px solid rgba($color: $black-color-rgb, $alpha: 0.2);

    @media (max-width: $md) {
      width: calc(50% - 17px);
      display: inline-flex;

      &:nth-child(2n) {
        margin-left: 30px;
      }
    }
    @media screen and (min-width: $md) and (max-width: $lg) {
      height: 142px;
    }
    @media (max-width: $xs) {
      width: 100%;
      display: inline-flex;
      margin-left: 0 !important;
    }

    .item-card {
      .contact-icon {
        margin-bottom: 16px;
      }

      a {
        text-decoration: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $title-color;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $title-color;
      }
    }
  }

  .button-schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    background: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $primary-color;
    border: 1px solid #d3d3d3;
    border-radius: 5px;

    @media (max-width: 991px) {
      width: 100%;
    }

    img, svg {
      margin-left: 7px;
    }
  }

  .input-icons {
    width: 100%;
    margin-bottom: 26px;
    border: 1px solid transparent;
    border-radius: 5px;
    position: relative;

    img {
      position: absolute;
      top: calc(50% - 10px);
      left: 14px;
    }

    .input-field {
      width: 100%;
      padding: 10px 10px 10px 50px;
      height: 66px;
      background: #f2f2f2;
      border-radius: 5px;
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: $black;

      &::placeholder {
        color: #828282;
        opacity: 1;
      }

      &:focus {
        padding: 10px 10px 10px 50px;
        outline-color: $primary-color;
      }
    }

    &.message {
      .input-field {
        height: 170px;
      }

      img {
        top: 15px;
      }
    }

    .message-field {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 13px;
    }
  }

  .send-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
    position: relative;

    @media screen and (max-width: $md) {
      display: block;
    }

    .send-button-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: $title-color;
      opacity: 0.7;
      margin: 16px 0;
    }

    .send-me {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 60px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: $white;
      border: none;
      background: $secondary-color;
      border-radius: 5px;

      img, svg {
        margin-left: 7px;
      }

      @media (max-width: $md) {
        margin-top: 30px;
        width: 100%;
      }
      @media (max-width: 600px) {
        margin-top: 30px;
        padding: 10px 15px;
      }
    }
  }

  .success-message {
    font-size: 16px;
    color: $primary-color;
    position: absolute;
    top: -20px;
    display: none;
  }
}
