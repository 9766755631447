.videoBtn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  &.header-3 {
    transform: translate(-50%, calc(-50%));
    @media (max-width: $xs) {
      transform: translate(-50%, 0%);
    }

    .videoPlayButton {
      width: 210px;
      height: 210px;

      @media (max-width: $md) {
        width: 200px;
        height: 200px;
      }

      @media (max-width: $xs) {
        width: 150px;
        height: 150px;
      }

      svg {
        width: 120px;
        height: 120px;
        @media (max-width: $md) {
          width: 90px;
          height: 90px;
        }

        @media (max-width: $xs) {
          width: 65px;
          height: 65px;
        }
      }
    }
  }

  .videoPlayButton {
    background-color: transparent;
    border: none;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: relative;

    svg {
      position: absolute;
      z-index: 10;
      top: 50%;
      width: 60px;
      transform: translate(-50%, -50%);
      left: 50%;
      height: 60px;
    }

    span {
      -webkit-animation: video_button 3s linear infinite;
      animation: video_button 3s linear infinite;
      background-color: #edbf48;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) !important;
      z-index: 9;

      &:nth-child(2) {
        animation-delay: calc(0.5s * 1);
      }

      &:nth-child(3) {
        animation-delay: calc(0.5s * 2);
      }

      &:nth-child(4) {
        animation-delay: calc(0.5s * 3);
      }
    }
  }

  @keyframes video_button {
    0% {
      width: 0;
      height: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .middle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
  }
}
