/* **************************************************
    Footer section
************************************************** */

footer {
  background-color: $bg-color-footer-blog;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 50px;

  @media screen and (max-width: $sm) {
    padding-bottom: 80px;
  }

  .footer-header {
    img {
      width: 120px;
    }

    h2 {
      text-align: center;
      color: $white;
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
    }
  }

  .social-media {
    border-bottom: 1px solid rgba($color: $white-color-rgb, $alpha: 0.5);
    padding: 32px 0 38px 0;

    a {
      background-color: $white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $title-color;
      text-decoration: none;
      font-size: 20px;
      transition: $transition-default;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

  // Footer copyrights
  .footer-copyrights {
    margin-top: 40px;
    padding-bottom: 10px;

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      opacity: 0.8;
      color: $white;
    }
  }
}
