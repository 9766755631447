/* **************************************************
    Hero banner.js section
************************************************** */

.hero-banner {
  background: $bg-color-banner;
  position: relative;
  overflow: hidden;

  .banner-space {
    @media (max-width: $md) {
      margin-top: 50px;
    }

    @media (max-width: $sm) {
      margin-top: 25px;
    }
  }

  .social-media {
    position: absolute;
    right: 30px;
    bottom: 50px;
    z-index: 999;

    li {
      margin-bottom: 20px;

      a {
        background: #3a3f4e;
        color: $white;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 18px;
        border-radius: 5px;
        &:hover {
          background: $black;
        }
      }
    }
  }

  .circle-background {
    background: $hero-circle-bg;
    filter: blur(100px);
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 10;
    height: 800px;
    width: 800px;
    border-radius: 50%;
    bottom: 0;
    transform: rotate(-176.36deg);
  }

  .hero-profile {
    position: relative;
    z-index: 100;
    height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .illustrator {
      background-image: url("../../img/hero-banner/Illustration.png");
      background-repeat: repeat-x;
      background-position-y: bottom;
      background-size: 61% 88%;
      z-index: 99999;
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        background-image: url("../../img/hero-banner/Illustration.png");
        background-repeat: repeat-x;
        background-position-y: bottom;
        background-size: 61% 88%;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        left: calc(106% - 26px);
        width: 100%;
        transform: scaleX(-1);
      }
    }

    > div {
      z-index: 999;
    }

    @media screen and (max-height: 699px) and (min-width: $md) {
      min-height: 700px;
    }

    @media screen and (max-width: $xs) {
      min-height: calc(68vw + 50px);
      height: 100%;
    }

    img {
      width: 94%;
      max-width: calc(100vh / 1.6);
      z-index: 999;

      @media screen and (max-width: $md) {
        max-height: calc(100vh - 150px);
        width: auto !important;
      }
      @media screen and (max-width: $sm) {
        max-height: 100%;
        width: 100% !important;
      }
      @media screen and (max-width: $xs) {
        max-height: 100%;
        width: 70% !important;
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: calc(50% - 23px);
      top: 0;
      width: 100%;
      height: 100%;
      background: $hero-right-bg;
      z-index: 11;

      @media screen and (max-width: 576px) {
        left: calc(50% - 15px);
      }
    }

    &:after {
      left: calc(150% - 25px);
      width: 100%;
      transform: scaleX(-1);
    }

    .overlay {
      width: 200%;
      height: 100%;
      background: rgba($color: $white-color-rgb, $alpha: 0.1);
      mix-blend-mode: overlay;
      z-index: 19;
      position: absolute;
      left: calc(50% - 25px);

      @media screen and (max-width: 576px) {
        left: calc(50% - 15px);
      }
    }
  }

  .hero-content {
    position: relative;
    z-index: 100;
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: $md) {
      min-height: 100%;
      background-color: $white;
      padding: 30px 0;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 200%;
        top: 0;
        left: -50%;
        background-color: $white;
        z-index: -1;
      }
    }

    @media (max-width: $sm) {
      padding: 30px 30px;
    }

    @media (max-width: $xxs) {
      padding: 30px 15px;
    }
  }

  .hero-header {
    padding-top: 40px;

    @media screen and (max-width: $sm) {
      padding-top: 20px;
    }

    img {
      width: 30px;
      height: 30px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      color: $title-color;

      @media (max-width: $xs) {
        font-size: 18px;
      }
    }
  }

  .hero-title {
    position: relative;
    h1 {
      max-width: 100%;
      font-weight: 700;
      font-size: 64px;
      line-height: 100%;
      margin-top: 20px;
      color: $title-color;

      span.fan-icons {
        position: absolute;
        bottom: 0;
      }

      @media (max-width: $sm) {
        font-size: 46px;
      }

      @media (max-width: $xs) {
        max-width: 420px;
        svg {
          height: 46px;
          width: 46px;
        }
      }

      @media (max-width: $xxs) {
        font-size: 26px;
        max-width: 230px;
      }
    }

    span.fan-icons img {
      animation: moving 7s 2 alternate;
      @media (max-width: $xs) {
        width: 65px;
      }
    }
  }

  .hero-pera {
    p {
      max-width: 800px;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: $black;
      margin-top: 15px;
      margin-bottom: 31px;

      @media (max-width: $md) {
        max-width: 500px;
        margin-bottom: 36px;
      }
    }
  }

  .hero-button {
    a,
    button {
      border: none;
      padding: 20px 32px;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      border-radius: 55px;
      color: $black;
      text-decoration: none;

      @media (max-width: $xs) {
        font-size: 14px;
        padding: 15px 20px;
      }

      @media (max-width: $xxs) {
        padding: 10px 16px;
      }
    }

    a.download-btn,
    button.download-btn {
      color: $white;
      background: $btn-bg;
    }

    a.lets-talk-btn,
    button.lets-talk-btn {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      background-color: transparent;
      border: 1px solid $primary-light-color;

      svg {
        margin-left: 10px;
      }
    }
  }

  //******* Desing Marketer Hero Banner  **********//
  &.designer {
    .hero-profile img {
      transform: translate(-10%, -97px);

      @media screen and (max-width: $xs) {
        transform: translateX(-25px);
      }
    }

    .social-media {
      margin-bottom: 25px;
      li {
        margin-right: 12px;
      }
    }

    .social-media a {
      &:hover {
        background-color: $primary-color;

        i {
          color: $white;
        }
      }

      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
    }
  }

  //******* Digital Marketer Hero Banner  **********//
  &.digital-marketer {
    background: $digital-hero-banner-bg;

    .sun-icon {
      position: absolute;
      top: -80px;
      right: 16px;
    }

    .hero-title h1 {
      font-size: 64px;

      @media screen and (max-width: $lg) {
        font-size: 50px;
      }
      @media screen and (max-width: $xs) {
        font-size: 35px;
        max-width: 370px;
      }
      @media screen and (max-width: $xxs) {
        font-size: 25px;
        max-width: 270px;
        line-height: 140%;
      }
    }

    .hero-pera p {
      margin-bottom: 30px;
    }

    .hero-profile {
      img {
        transform: translateY(-50%);
        @media screen and (max-width: $xs) {
          transform: translateY(-10%);
        }
      }

      &:before,
      &:after {
        display: none;
      }
      @media (min-width: $md) {
        img {
          max-width: calc(100vh / 1.3);
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.75;
  }

  100% {
    transform: scale(1.35);
    opacity: 0;
  }
}

@keyframes moving {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(300deg);
  }
  35% {
    transform: rotate(1500deg);
  }
  75% {
    transform: rotate(-300deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
