/* **************************************************
    This file contains all application-wide Sass variables.
************************************************** */
@import "../css_variables.scss";

// Typography
//$title-font-family: 'Libre Franklin', sans-serif;
//$link-font-family: 'Libre Franklin', sans-serif;
//$lf-font-family: 'Libre Franklin', sans-serif;
$body-font-family: "Outfit", sans-serif;

$font-size-base: 14px;

// Colors
$primary-color: var(--primary-color);
$primary-color-rgb: var(--primary-color-rgb);
$primary-light-color: var(--primary-light-color);
$primary-light-color-rgb: var(--primary-light-color-rgb);
$secondary-color: var(--secondary-color);

$body-bg: var(--body-bg);

$bg-color-banner: var(--bg-color-banner);
$bg-color-project: var(--bg-color-project);
$bg-color-project-count: var(--bg-color-project-count);
$bg-color-blog: var(--bg-color-blog);
$bg-color-footer-blog: var(--bg-color-footer);
$bottom-to-top: var(--bottom-to-top);

$gray-color: var(--gray-color);
$text-color: var(--text-color);

$title-color: var(--title-color);
$yellow-text-color: var(--yellow-text-color);
$text-color-rgb: var(--text-color-rgb);
$blue-shades: var(--blue-shades);
$white: var(--white);
$white-color-rgb: var(--white-rgb);
$black: var(--black);
$black-color-rgb: var(--black-rgb);

// Transitions
$transition-default: all 0.3s ease-in-out;

// ALL Gradient styles
$sub-menu-bg-color: var(--sub-menu-bg-color);
$btn-bg: var(--btn-bg);
$hero-circle-bg: var(--hero-circle-bg);
$hero-right-bg: var(--hero-right-bg);
$scroll-bottom: var(--scroll-bottom);
$tab-bg: var(--tab-bg);
$text-bg: var(--text-bg);
$post-hover-bg: var(--post-hover-bg);
$hero-social-bg: var(--hero-social-bg);
$hero-avatar-bg: var(--hero-avatar-bg);
$digital-hero-banner-bg: var(--digital-hero-banner-bg);
$expertise-icon-bg: var(--expertise-icon-bg);
$experience-line-bg: var(--experience-line-bg);
$mobile-nav-active-bg: var(--mobile-nav-active-bg);
// Breakpoinst
$xl: 1399px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$xxs: 360px;
