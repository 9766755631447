/* **************************************************
    Experience section
************************************************** */
.experience {
  padding: 0 0 120px 0;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $md) {
    padding-bottom: 60px;
  }

  @media screen and (max-width: $sm) {
    padding-top: 20px;

    .section-heading {
      margin: 0 50px 60px 0;
    }
  }

  .experience-top {
    padding-bottom: 50px;

    @media screen and (max-width: $sm) {
      padding-bottom: 30px;
    }
  }

  ul.nav.nav-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    background: #f7faff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px;

    @media screen and (max-width: 499px) {
      margin: auto;
    }

    li {
      &:last-child {
        margin-left: 2px;
      }

      .nav-link {
        font-size: 18px;
        font-weight: 500;
        color: $title-color;
        background-color: transparent;
        border: none;
        border-radius: 50px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 2px 15px;

        svg {
          margin-right: 8px;
        }

        &.active {
          color: $white;
          background: $tab-bg;

          svg path {
            fill: $white;
          }
        }
      }
    }
  }

  // Experience timeline view
  .experience__timeline {
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 50px;

      li {
        display: flex;
        position: relative;
        gap: 0;

        @media only screen and (max-width: $sm) {
          gap: 20px;
        }

        &:last-child .right::after {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          background: $gray-color;
          opacity: 0.7;
          width: 2px;
          height: calc(50% + 50px);
          top: 50%;
          left: 239px;
          @media only screen and (max-width: 767px) {
            left: 14px;
          }
        }

        &:last-child::after {
          display: none;
        }

        &::before {
          content: "";
          position: absolute;
          background: $gray-color;
          opacity: 0.7;
          width: 2px;
          height: 50%;
          top: 0;
          left: 239px;
          @media only screen and (max-width: 767px) {
            height: 50%;
            left: 14px;
          }
        }

        &:first-child::before {
          display: none;
        }

        &.active {
          + li::before {
            background-color: $primary-color;
          }

          &::after {
            background-color: $primary-color;
          }

          .right {
            //max-height: 1000px;
            cursor: text;

            p {
             max-height: 500px;
              transition: 1.8s max-height;
            }
          }

          .right::after {
            background-color: $primary-color;
          }

          .left {
            p {
              color: $primary-color;
            }

            .circle {
              border-color: $primary-color;

              span {
                background-color: $primary-color;
              }
            }
          }
        }

        .left {
          display: flex;
          align-items: center;
          min-width: 300px;
          width: 300px;
          position: relative;

          @media only screen and (max-width: $sm) {
            width: auto;
            min-width: auto;
          }

          p {
            text-align: left;
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 110%;
            color: $text-color;
            display: block;
            width: 100%;

            @media only screen and (max-width: $sm) {
              display: none;
            }
          }

          .circle {
            background-color: $white;
            display: flex;
            width: 30px;
            min-width: 30px;
            height: 30px;
            border: 2px solid $text-color;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            z-index: 10;
            cursor: pointer;
            margin-right: 45px;

            @media only screen and (max-width: $sm) {
              margin-right: 0;
            }

            span {
              width: 10px;
              height: 10px;
              display: block;
              border-radius: 50%;
              background: $text-color;
            }
          }
        }

        .right {
          width: 100%;
          padding: 22px 42px;
          color: $title-color;
          box-shadow: 5px 10px 20px rgba(124, 113, 105, 0.15);
          border-radius: 10px;
          border: 0.5px solid rgba(0, 0, 0, 0.2);
          background-color: $white;
          display: flex;
          flex-direction: column;
          row-gap: 7px;
          position: relative;
          overflow: hidden;
          cursor: pointer;

          &::before {
            content: "";
            position: absolute;
            background: $experience-line-bg;
            opacity: 0.7;
            border-radius: 0 5px 5px 0;
            width: 4px;
            height: 70%;
            top: 50%;
            transform: translateY(-50%);
            left: -1px;
          }

          h2 {
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
          }

          h3 {
            margin: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 130%;
          }

          .location {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 110%;
              display: block;
              color: $text-color;
            }
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            max-height: 0;
            transition: 0.6s max-height;
            overflow: hidden;
          }
        }
      }
    }
  }

  // ************     List Views    ************** //
  .experience__item {
    color: $title-color;
    background: #ffffff;
    border-radius: 10px;
    padding: 1.5rem 3rem;
    position: relative;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      background: $experience-line-bg;
      border-radius: 0 5px 5px 0;
      width: 4px;
      height: calc(100% - 40px);
      top: 20px;
      left: -1px;
    }

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      color: $primary-color;
      margin-bottom: 12px;

      span {
        background: $text-bg;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }

    h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.0184em;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #484e61;
    }

    img {
      margin-top: -1rem;
    }

    span.address {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      color: #4f4f4f;
    }

  }

  .experience_list_container .experience__item img {
    margin-top: -0.1rem;
  }
}
