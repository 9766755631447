/* **************************************************
    Mobile menu
************************************************** */

.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 999;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.16);

    .mobile-menu-items {
        min-height: 75px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        a {
            text-decoration: none;
            color: $black;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            transition: all .3s ease-in-out;

            &.active {
                transition: all .3s ease-in-out;
                svg  {
                  fill: $primary-color;

                    path {
                        stroke: none;
                    }
                    defs {
                        display: block;
                    }
                }
//
                .label {
                    background: $mobile-nav-active-bg;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &:not(.active) .experience-icon svg path {
              &:nth-child(7) {
                fill: #D1D1D6;
                stroke: none;
              }
              &:nth-child(8) {
                fill: #D1D1D6;
                stroke: none;
              }
            }

            svg defs {
                display: none;
            }


          .label {
            font-size: 13px;
            color: #8E8E93;
            font-weight: 500;
          }
        }
    }
}