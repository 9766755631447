/***************************************************
    Color theme-2 styles
************************************************** */

@import "../abstracts/variables";

.color-theme-2 {
  .project-section .feature-tab .nav-link,
  .project-section .section-heading {
    color: $black;
  }

  .project-section .feature-tab .nav-link.active {
    border-color: $black;
  }

  .project-list-section .box h2 sup,
  .project-list-section .box h2 span {
    background: $white;
    color: $white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .project-list-section .box p {
    color: $white;
  }

  .project-list-section .box {
    border-color: $white;
  }

  .see-more-btn {
    color: $black;
    border-color: $black;
  }

  footer .social-media a {
    color: $primary-color;

    &:hover {
      background: $black;
    }
  }

  .title-signature:not(.white):not(.black):after {
    background-image: url("../../img/title-signature.svg");
  }

  .hero-banner .hero-button button.download-btn {
    background: $primary-color;
  }

  .hero-banner .hero-button a.lets-talk-btn,
  .hero-banner .hero-button button.lets-talk-btn {
    border-color: $black;
  }

  .testimonial-section .testimonial-slider {
    background-image: url("../../img/background-img.png");
  }
}
