/* **************************************************
   Blog seciton
************************************************** */

.blog-section {
  position: relative;
  overflow: hidden;
  padding: 110px 0;

  @media screen and (max-width: $sm) {
    padding: 55px 0;
  }

  &:after {
    content: "";
    height: 552px;
    width: 100%;
    position: absolute;
    background: $bg-color-blog;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: $xl) {
      height: 507px;
    }
    @media screen and (max-width: $lg) {
      height: 462px;
    }
    @media screen and (max-width: $md) {
      height: 492px;
    }
  }

  .section-heading {
    color: $white;
    margin-bottom: 80px;

    sub {
      background-color: $white;
    }
  }

  .blogs-item {
    display: block;
    transition: 0.2s ease;

    a {
      text-decoration: none;
      cursor: pointer;

      .blog-item {
        padding: 16px;
        background: $white;
        border: 1px solid $white;
        box-shadow: 10px 50px 100px rgba(124, 113, 105, 0.1);
        border-radius: 12px;
        margin-bottom: 25px;

        .blog-image {
          border-radius: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .card-body {
          padding: 19px 0;

          .blog-date {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $text-color;
            gap: 6px;
            margin-bottom: 10px;

            .blog-date-item {
              font-size: 16px;
              padding: 0;
              margin: 0;
              line-height: 0;
            }
          }

          .blog-card-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;
            color: $title-color;
            margin-top: 7px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word !important;
            min-height: 62px;

            @media (max-width: 600px) {
              font-size: 16px;
            }
          }

          .blog-review {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 13px;

            .reviewer-image {
              width: 49px;
              height: 49px;
              border-radius: 50%;

              img {
                border-radius: 50%;
                height: 100%;
                width: 100%;
              }
            }

            .reviewer-name {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              margin: 0;
              padding-left: 13px;
              color: $title-color;

              span {
                color: $text-color;
              }
            }
          }

          .blog-read-more {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $title-color;
            margin-top: 20px;

            a {
              display: flex;
              color: $primary-color;
              text-decoration: none;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  .blogs-item:nth-child(1),
  .blogs-item:nth-child(2) {
    display: inline-block;
  }

  @media screen and (min-width: 992px) {
    .blogs-item:nth-child(3) {
      display: inline-block;
    }
  }
}

.blog-project-modal {
  padding: 0;
  border-radius: 16px;

  .details {
    padding: 20px 30px;
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: $black;
    margin: 0 0 16px 0;

    @media (max-width: $xs) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #727272;
    word-break: break-word !important;
    margin-bottom: 16px;
  }
}
