/* **************************************************
    Color variable
************************************************** */
:root {
    /* ** Brand Colors One ** */
    --primary-color: #F7971E;
    --primary-color-rgb: 247, 151, 30;
    --primary-light-color: #FFD200;
    --primary-light-color-rgb: 255, 210, 0;
    
    /* ** Brand Colors Two ** */
    --secondary-color: #F7971E;

    /* ** Others Colors ** */
    --bg-color-banner: linear-gradient(119.99deg, rgba(247, 151, 30, 0.07) 21.9%, rgba(255, 210, 0, 0.07) 125.17%);
    --bg-color-project: linear-gradient(119.99deg, rgba(247, 151, 30, 0.1) 21.9%, rgba(255, 210, 0, 0.1) 125.17%);
    --bg-color-project-count: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --bg-color-blog: #F7971E;
    --bg-color-footer: #F7971E;
    --body-bg: #F6F6F6;
    --bottom-to-top:#F9BD23;
    --yellow-text-color: #F7971E;
    --text-color: #828282;
    --title-color: #23262f;
    --text-color-rgb: 91, 56, 227;
    --blue-shades: rgba(248, 156, 28, 0.1);
    --gray-color:#4F4F4F;
    --white: #ffffff;
    --white-rgb: 255, 255, 255;
    --black: #000000;
    --black-rgb: 0, 0, 0;


    //// Gradient styles
    --post-hover-bg: #F7971E;
    --hero-social-bg: #F7971E;
    --mobile-nav-active-bg: #F7971E;
    --hero-right-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --scroll-bottom: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --digital-hero-banner-bg: linear-gradient(119.99deg, rgba(247, 151, 30, 0.2) 21.9%, rgba(255, 210, 0, 0.2) 125.17%);
    --btn-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --tab-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --text-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --hero-avatar-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --sub-menu-bg-color: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --hero-circle-bg: linear-gradient(119.99deg, #D6CDF9 21.9%, #6BDCFF 125.17%);
    --expertise-icon-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);
    --experience-line-bg: linear-gradient(119.99deg, #F7971E 21.9%, #FFD200 125.17%);

}
