/* **************************************************
    See more button
************************************************** */
.see-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: $white;
  padding: 15px 30px;
  background: transparent;
  border: 1px solid $white;
  border-radius: 55px;
  margin: 34px auto 0 auto;

  &.primary {
    border: 1px solid $primary-color;
    color: $primary-color;
    z-index: 1000;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
}
