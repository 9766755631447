/* **************************************************
   Preloader styles
************************************************** */
.preloader {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999999;
  }
  
  .preloader .animation-preloader {
    z-index: 1000;
  }
  
  .preloader .animation-preloader .spinner {
    -webkit-animation: spinner 1s infinite linear;
            animation: spinner 1s infinite linear;
    border-radius: 50%;
    border: 5px solid rgba($color: $black-color-rgb, $alpha: 0.2);
    border-top-color: $primary-color;
    height: 7em;
    margin: 0 auto 3.5em auto;
    width: 7em;
  }
  
  @media (max-width: 767px) {
    .preloader .animation-preloader .spinner {
      width: 7.5em;
      height: 7.5em;
      margin: 0 auto 1.5em auto;
    }
  }
  
  .preloader .animation-preloader .txt-loading {
    font: bold 5em "Exo 2", sans-serif, "Roboto", sans-serif;
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  @media (max-width: 767px) {
    .preloader .animation-preloader .txt-loading {
      font-size: 2.5em;
    }
  }
  
  .preloader .animation-preloader .txt-loading .letters-loading {
    color: $primary-color;
    position: relative;
  }
  
  
  .preloader p {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 8px;
    color: #001659;
  }
  
  .preloader .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    z-index: 1;
    pointer-events: none;
  }
  
  .preloader .loader .row {
    height: 100%;
  }
  
  .preloader .loader .loader-section {
    padding: 0px;
  }
  
  .preloader .loader .loader-section .bg {
    background-color: #fff;
    height: 100%;
    left: 0;
    width: 100%;
    -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .preloader.loaded .animation-preloader {
    opacity: 0;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
  }
  
  .preloader.loaded .loader-section .bg {
    width: 0;
    -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
    transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  }


  @-webkit-keyframes spinner {
    to {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
    }
  }
  
  @keyframes spinner {
    to {
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
    }
  }
  
  
  