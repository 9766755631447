.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 15px 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 80px;
  padding-bottom: 100px;

  &.expertise-overlay {
    background-color: rgba($color: #000000, $alpha: 0.75);
  }

  .expertise-modal {
    background-color: #fff;
    max-width: 750px;
    width: 100%;
    margin: auto;
    margin-top: 35px;

    @media (max-width: $sm) {
      margin-top: 5px;
    }
    .modal-close-btn {
      top: 15px;
      right: 20px;
    }
  }

  @media (max-width: $sm) {
    padding-top: 100px;
    padding-bottom: 200px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.messageIcon{
  width: 180px;
}
.contact-modal-img{
  margin-top: 80px;
}
.contact-modal{
  height: 633px;

  @media (max-width: 1400px) {
    height: 550px;
  }
  @media (max-width: $sm) {
    height: 100%;
  }

}
