/* **************************************************
    Scroll to top button
************************************************** */

.bottom-top-button {
  position: fixed;
  display: inline-block;
  background-color: #3a3f4e;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  bottom: 50px;
  right: 25px;
  text-decoration: none;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  @media (max-width: $xs) {
    display: none;
  }
  span {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;

    &::after {
      content: "\f101";
      transform: rotate(-90deg);
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      font-size: 26px;
      color: #fff;
      animation: bottom-up 2s ease-in infinite;
    }
  }
}

@keyframes bottom-up {
  from {
    opacity: 1;
    transform: translateY(10%) rotate(-90deg);
  }
  to {
    opacity: 0;
    transform: translateY(-100%) rotate(-90deg);
  }
}

.bottom-top-button:active {
  background-color: #555;
}

.bottom-top-button.show {
  opacity: 1;
  visibility: visible;
}
