/* **************************************************
    Utility Styles
************************************************** */

.section-heading {
  font-weight: 600;
  font-size: 48px;
  color: $title-color;
  position: relative;

  @media (max-width: $sm) {
    font-size: 36px;
  }

  sub {
    color: transparent;
    background: $primary-color;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    font-size: 48px;
    position: absolute;
    right: -10px;
    bottom: 10px;
  }
}

.section-top {
  margin-bottom: 60px;
}

.title-signature {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -56px;
    right: -30px;
    background-image: url('../../img/title-signature.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    width: calc(100% + 30px);
    height: 50px;
  }

  &.white:after {
    background-image: url('../../img/title-signature-white.svg');
  }
  &.black:after {
    background-image: url('../../img/title-signature-black.svg');
  }
}
@media screen and (max-width: $md) {
  .border-end-0-md {
    border-right: none !important;
  }
}
